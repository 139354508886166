import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "customerCount",
    "customerCheckbox",
    "sendLaterInputs",
    "sendNowIcon",
    "sendLaterIcon",
    "submitButton"
  ]

  connect() {
    const dialog = this.element.querySelector("dialog")
    dialog.addEventListener("close", this.updateCount);
    this.updateCount()
    if (!this.sendLaterInputsTarget.disabled) {
      this.chooseSendLater()
    }
  }

  disconnect() {
    const dialog = this.element.querySelector("dialog")
    dialog.removeEventListener("close", this.updateCount);
  }

  updateCount = () => {
    const count = this.customerCheckboxTargets.filter(checkbox => checkbox.checked).length
    this.customerCountTarget.innerText = count === 1 ? "1 customer" : `${count} customers`
    if (count == 0) {
      this.customerCountTarget.classList.add("text-red-600")
    } else {
      this.customerCountTarget.classList.remove("text-red-600")
    }
  }

  chooseSendNow() {
    this.sendLaterInputsTarget.disabled = true
    this.sendLaterInputsTarget.classList.add("hidden")
    const icon = this.sendNowIconTarget?.innerHTML || ""
    this.submitButtonTarget.innerHTML = `${icon} Send Bulk Notification`
  }

  chooseSendLater() {
    this.sendLaterInputsTarget.disabled = false
    this.sendLaterInputsTarget.classList.remove("hidden")
    const icon = this.sendLaterIconTarget?.innerHTML || ""
    this.submitButtonTarget.innerHTML = `${icon} Schedule Bulk Notification`
  }

  filterCustomers(e) {
    const url = `${window.location.href.split('?')[0]}?location_id=${e.target.value}`
    const turboFrame = document.querySelector("#customers")
    turboFrame.setAttribute("src", url)
  }
}
